import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import user from '@/services/user.js'
import { PricingService } from '@/services/service-pricing.js'
import { LocationService } from '@/services/service-location.js'
import router from './router'
import ApirUrls from './utils/api-urls'
import Constants from './utils/constants.js'
import utils from '@/utils/utils.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: !!Cookies.get('TB_SESSION'),
    tb4t: !!Cookies.getJSON('TB_SESSION') && Cookies.getJSON('TB_SESSION').tb4t,
    userData: {},
    billingData: {},
    pricingService: new PricingService(),
    locationService: new LocationService(),
    received: {
      billing: false,
      user: false
    },
    toRoute: '',
    activeUser: false,
    submitting: false,
    modalOpen: false,
    showOverlay: false,
    userDataRequested: false,
    captchToken: null,
    mfaEmailHint: null,
    mfaType: null,
    isMFAShowEnableNotice: false,
    isMFAShowDisableNotice: false,
    isShowBackupCodeAlert: false,
    isMFAAuthTokenExpired: true,
    isMFALoginTokenExpired: true,
    isMcafeeDiscountApplied: false
  },
  mutations: {
    isMFALoginTokenExpired (state, data) {
      state.isMFALoginTokenExpired = data
    },
    isMFAAuthTokenExpired (state, data) {
      state.isMFAAuthTokenExpired = data
    },
    isTBMcafeeDiscountApplied (state, isMcafeeDiscountApplied) {
      state.isMcafeeDiscountApplied = isMcafeeDiscountApplied
    },
    isMFAShowEnableNotice (state, data) {
      state.isMFAShowEnableNotice = data
    },
    isMFAShowDisableNotice (state, data) {
      state.isMFAShowDisableNotice = data
    },
    checkLoggedInState (state) {
      state.isLoggedIn = !!Cookies.get('TB_SESSION')
    },
    userData (state, data) {
      state.userData = data
      state.tb4t = data.teamRole || ''
      state.received.user = true
    },
    billingData (state, { data, isBillingReceived }) {
      state.billingData = data
      state.received.billing = isBillingReceived
    },
    saveNextRoute (state, data) {
      state.toRoute = data
    },
    destroyNextRoute (state, data) {
      state.toRoute = null
    },
    login (state) {
      if (process.env.NODE_ENV === 'development') {
        document.cookie = 'TB_SESSION=%7B%22bearType%22%3A%22Little+TunnelBear%22%7D'
      }
      state.isLoggedIn = true
    },
    logout (state) {
      if (process.env.NODE_ENV === 'development') {
        document.cookie = 'TB_SESSION=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      }
      state.isLoggedIn = false
    },
    submitting (state, submitting) {
      state.submitting = submitting
    },
    modalOpen (state, status) {
      state.modalOpen = status
    },
    userDataRequested (state) {
      state.userDataRequested = true
    },
    activeUser (state) {
      state.activeUser = true
    },
    setCaptchaToken (state, captchaToken) {
      state.captchToken = captchaToken
    },
    setMFAType (state, mfaType) {
      state.mfaType = mfaType
    },
    setMFAEmailHint (state, email) {
      state.mfaEmailHint = email
    },
    setIsShowBackupCodeAlert (state, data) {
      state.isShowBackupCodeAlert = data
    }
  },
  actions: {
    isTBMcafeeDiscountApplied ({ commit }, isMcafeeDiscountApplied) {
      commit('isTBMcafeeDiscountApplied', isMcafeeDiscountApplied)
    },
    async getNextRoute ({ commit, state }) {
      if (state.toRoute && state.toRoute.name) {
        const toRoute = state.toRoute
        commit('destroyNextRoute')
        return toRoute
      } else {
        throw null
      }
    },
    saveNextRoute ({ commit }, route) {
      commit('saveNextRoute', route)
    },
    changeEmail ({ commit }, email) {
      commit('pendingEmail', email)
    },
    submitting ({ commit }, submitting) {
      commit('submitting', submitting)
    },
    clearMFAAuthToken ({ commit }, isCleared) {
      commit('isMFAAuthTokenExpired', isCleared)
      localStorage.removeItem(Constants.MFA_AUTH_TOKEN)
    },
    clearMFALoginToken ({ commit }, isCleared) {
      commit('isMFALoginTokenExpired', isCleared)
      localStorage.removeItem(Constants.MFA_LOGIN_TOKEN)
    },
    modalOpen ({ commit }, status) {
      commit('modalOpen', status)
    },
    activeUser ({ commit }) {
      commit('activeUser')
    },
    captchaToken ({ commit }, token) {
      commit('setCaptchaToken', token)
    },
    setBackupCodeAlert ({ commit }, isAlertShow) {
      commit('setIsShowBackupCodeAlert', isAlertShow)
    },
    isMFAShowEnableNotice ({ commit, dispatch }, isShowNotice) {
      commit('isMFAShowEnableNotice', isShowNotice)
    },
    isMFAShowDisableNotice ({ commit, dispatch }, isShowNotice) {
      commit('isMFAShowDisableNotice', isShowNotice)
    },
    moveToMFAScreen ({ commit, dispatch }) {
      commit('isMFAAuthTokenExpired', true)
      router.replace({ name: 'mfa-enable-disable' })
    },
    moveToLoginScreen ({ commit }) {
      commit('isMFALoginTokenExpired', true)
      router.push({ name: 'login' })
    },
    moveToMFALoginScreen ({ commit }, { username }) {
      commit('setMFAEmailHint', username)
      router.replace({ name: 'mfa-auth' })
    },
    deleteAccount ({ commit }, password) {
      return this.$http.delete(ApirUrls.DELETE, {
        data: {
          password
        }
      })
    },
    isMFAAuthTokenExpired ({ commit }) {
      if (!localStorage.getItem(Constants.MFA_AUTH_TOKEN)) {
        commit('isMFAAuthTokenExpired', true)
        return true
      }
      return false
    },
    isMFALoginTokenExpired ({ commit }) {
      if (!localStorage.getItem(Constants.MFA_LOGIN_TOKEN)) {
        commit('isMFALoginTokenExpired', true)
        return true
      }
      return false
    },
    async getBillingData ({ commit, state }, forceRefresh) {
      const returnExistingData = state.received.billing && !forceRefresh
      if (!state.isLoggedIn || returnExistingData) return
      let response = ''
      try {
        response = await this.$http.get(ApirUrls.GET_BILLING_DETAILS)
      } catch (err) {
        if (err.response.status === 403) {
          commit('logout')
        }
        throw err
      }
      const data = response.data
      // if there is already billing data then no need to call the api
      const isBillingReceived = true
      commit('billingData', { data, isBillingReceived })
    },
    async getUserData ({ commit, dispatch, state }, forceRefresh) {
      const returnExistingData = state.received.user && !forceRefresh
      if (!state.isLoggedIn || returnExistingData) return
      try {
        const deprecatedCoreAccountResponse = await this.$http.get(ApirUrls.DEPRICATTED_ACCOUNT)
        const accountRes = await this.$http.get(ApirUrls.ACCOUNT)
        deprecatedCoreAccountResponse.data.nextDataReset = accountRes.data.nextDataReset
        commit('userData', user.filterUserData(deprecatedCoreAccountResponse.data))
        commit('userDataRequested')
        dispatch('clearMFALoginToken')
      } catch (err) {
        if (err.response.status === 403) {
          commit('logout')
        }
        throw err
      }
    },
    async login ({ commit, dispatch, state }, credentials) {
      const passwordLoginData = {
        ...credentials,
        grant_type: Constants.GrantType.PASSWORD,
        device: Constants.DEVICE_ID
      }
      const tokenResponse = await this.$http.post(ApirUrls.TOKEN, passwordLoginData, {
        headers: { 'Content-Type': 'application/json' }
      })
      if (tokenResponse.data.mfa_type) {
        commit('setMFAType', tokenResponse.data.mfa_type.toUpperCase())
        localStorage.setItem(Constants.MFA_LOGIN_TOKEN, tokenResponse.data.login_token)
        commit('isMFALoginTokenExpired', false)
        const username = credentials.username
        dispatch('moveToMFALoginScreen', { username })
      } else {
        if (!tokenResponse.data.access_token) throw new Error('Missing token in /v2/token response')
        await dispatch('tokenCookie', tokenResponse.data.access_token)
      }
    },
    async tokenCookie ({ commit, dispatch }, data) {
      await this.$http.post(ApirUrls.TOKEN_COOKIE, {}, {
        headers: { 'Authorization': `Bearer ${data}` }
      })
      if (this.state.mfaType) {
        commit('login')
        dispatch('getUserData', true)
        router.push({ name: 'overview' })
      } else {
        const response = await this.$http.get(ApirUrls.DEPRICATTED_ACCOUNT)
        if (this.state.toRoute.path !== '/support' && response.data && response.data.teamRole && response.data.teamRole !== 'PENDING') {
          window.location.href = '/team/account/overview'
        } else {
          commit('login')
          dispatch('getUserData', true)
        }
      }
    },
    async createAccount ({ commit, dispatch, state }, data) {
      let response
      try {
        response = await this.$http.post(ApirUrls.CREATE_ACCOUNT, data)
      } catch (err) {
        throw null // let the UI choose a message for general failures
      }
      if (response.data.result === 'OK') {
        commit('login')
        dispatch('getUserData', true)
        return response
      } else {
        throw response.data.details
      }
    },
    async logout ({ commit, dispatch, state }) {
      commit('checkLoggedInState')
      const billingData = {}
      // clear billingReceived on logout
      const isBillingReceived = false
      if (!state.isLoggedIn) {
        commit('userData', {})
        commit('billingData', { billingData, isBillingReceived })
      } else {
        const response = await this.$http.post(ApirUrls.LOGOUT, {})
        if (response.data.result === 'PASS') {
          Cookies.set('tb_user', true, {
            expires: 7,
            secure: true
          })
          commit('logout')
          commit('userData', {})
          commit('billingData', { billingData, isBillingReceived })
          dispatch('clearMFAAuthToken', true)
          dispatch('clearMFALoginToken', true)
          dispatch('isMFAShowEnableNotice', false)
          dispatch('isMFAShowDisableNotice', false)
        } else {
          throw new Error()
        }
      }
    },
    async requestResetPassword ({ commit, dispatch }, data) {
      const genericErrorMessage = 'Grrr... Something went wrong with your request. Please try again later.'
      try {
        const response = await this.$http.post(ApirUrls.REQUEST_RESET, data)
        if (response.data.result === 'FAIL') {
          throw response.data.details || genericErrorMessage
        }
      } catch (error) {
        if (error.response && error.response.status >= 400) {
          throw genericErrorMessage
        } else {
          throw error
        }
      }
    },
    async getMFAAuthToken ({ commit, dispatch }, password) {
      const request = { password, device: Constants.DEVICE_ID }
      const response = await this.$http.post(ApirUrls.GET_MFA_AUTH, request, { headers: { 'Content-Type': 'application/json' } })
      localStorage.setItem(Constants.MFA_AUTH_TOKEN, response.data.ma_token)
      commit('isMFAAuthTokenExpired', false)
      const tokenExpiry = response.data.expires_in * 1000 // to convert seconds to milliseconds
      setTimeout(() => {
        dispatch('clearMFAAuthToken')
      }, tokenExpiry)
    },
    async enableMFA ({ commit, dispatch }) {
      const request = { ma_token: localStorage.getItem(Constants.MFA_AUTH_TOKEN) }
      await this.$http.post(ApirUrls.ENABLE_MFA, request, { headers: { 'Content-Type': 'application/json' } })
      dispatch('isMFAShowEnableNotice', true)
      dispatch('isMFAShowDisableNotice', false)
    },
    async disableMFA ({ commit, dispatch }, password) {
      const lastSavedMFAType = this.state.mfaType
      const request = { ma_token: localStorage.getItem(Constants.MFA_AUTH_TOKEN) }
      await this.$http.post(ApirUrls.DISABLE_MFA, request, { headers: { 'Content-Type': 'application/json' } })
      dispatch('isMFAShowDisableNotice', true)
      dispatch('isMFAShowEnableNotice', false)
      dispatch('getMFADetails')
      return lastSavedMFAType
    },
    async setEmailMFA ({ commit, dispatch }) {
      const isMFAAuthExpired = await dispatch('isMFAAuthTokenExpired')
      if (!isMFAAuthExpired) {
        const request = { ma_token: localStorage.getItem(Constants.MFA_AUTH_TOKEN) }
        await this.$http.post(ApirUrls.SET_EMAIL_MFA, request, { headers: { 'Content-Type': 'application/json' } })
      }
    },
    async resendEMFA ({ commit, dispatch }) {
      const isMFAAuthExpired = await dispatch('isMFAAuthTokenExpired')
      if (!isMFAAuthExpired) {
        const request = { ma_token: localStorage.getItem(Constants.MFA_AUTH_TOKEN) }
        await this.$http.post(ApirUrls.RESEND_EMFA, request, { headers: { 'Content-Type': 'application/json' } })
      }
    },
    async resendEMFALogin ({ commit, dispatch }) {
      const isMFALoginExpired = await dispatch('isMFALoginTokenExpired')
      if (!isMFALoginExpired) {
        const request = {
          username: this.state.mfaEmailHint,
          login_token: localStorage.getItem(Constants.MFA_LOGIN_TOKEN)
        }
        const response = await this.$http.post(ApirUrls.RESEND_EMFA_LOGIN, request, { headers: { 'Content-Type': 'application/json' } })
        return response
      }
    },
    async setTOTPMFA ({ commit, dispatch }) {
      const isMFAAuthExpired = await dispatch('isMFAAuthTokenExpired')
      if (!isMFAAuthExpired) {
        const request = { ma_token: localStorage.getItem(Constants.MFA_AUTH_TOKEN) }
        const response = await this.$http.post(ApirUrls.SET_TOTP_MFA, request, { headers: { 'Content-Type': 'application/json' } })
        return response.data
      }
    },
    async enrollMFA ({ commit, dispatch }, code) {
      const isMFAAuthExpired = await dispatch('isMFAAuthTokenExpired')
      if (!isMFAAuthExpired) {
        const request = {
          ma_token: localStorage.getItem(Constants.MFA_AUTH_TOKEN),
          mfa_token: code
        }
        const response = await this.$http.post(ApirUrls.ENROLL_MFA, request, { headers: { 'Content-Type': 'application/json' } })
        commit('setMFAType', response.data.mfa_type.toUpperCase())
        router.replace({ name: 'mfa-enable-disable' })
      }
    },
    async doMFACodeAuthentication ({ commit, dispatch }, requestData) {
      const isMFALoginExpired = await dispatch('isMFALoginTokenExpired')
      let apiUrl
      if (!isMFALoginExpired) {
        if (requestData.mfa_token) {
          requestData.grant_type = Constants.GrantType.MFA_LOGIN
          apiUrl = ApirUrls.MFA
        } else {
          requestData.grant_type = Constants.GrantType.MFA_RECOVERY
          apiUrl = ApirUrls.MFA_RECOVERY
        }
        requestData.device = Constants.DEVICE_ID
        requestData.username = this.state.mfaEmailHint
        requestData.login_token = localStorage.getItem(Constants.MFA_LOGIN_TOKEN)
        const tokenResponse = await this.$http.post(apiUrl, requestData, { headers: { 'Content-Type': 'application/json' } })
        dispatch('tokenCookie', tokenResponse.data.access_token)
      }
    },
    async generateBackupCodes ({ commit, dispatch }) {
      const isMFAAuthExpired = await dispatch('isMFAAuthTokenExpired')
      if (!isMFAAuthExpired) {
        const request = { ma_token: localStorage.getItem(Constants.MFA_AUTH_TOKEN) }
        const response = await this.$http.post(ApirUrls.GENERATE_NEW_RECOVERY_CODES, request, { headers: { 'Content-Type': 'application/json' } })
        return response.data
      }
    },
    async getRecoveryCodesPdf ({ commit, dispatch }) {
      const isMFAAuthExpired = await dispatch('isMFAAuthTokenExpired')
      if (!isMFAAuthExpired) {
        try {
          const request = { ma_token: localStorage.getItem(Constants.MFA_AUTH_TOKEN) }
          const resp = await this.$http.post(ApirUrls.GET_RECOVERY_CODES_PDF, request, { headers: { 'Content-Type': 'application/json' }, 'responseType': 'blob' })
          const url = window.URL.createObjectURL(new Blob([resp.data]))
          const link = document.createElement('a')
          const time = (new Date()).toLocaleTimeString('it-IT')
          const date = (new Date()).toISOString().split('T')[0]
          link.href = url
          link.setAttribute('download', `TunnelBear_recovery_codes_${date}_${time}.pdf`)
          document.body.appendChild(link)
          link.click()
        } catch (error) {
          error.response.data = JSON.parse(await error.response.data.text()) // blob request type error
          throw error
        }
      } else {
        throw new Error() // show error when auth token expired and user is still on generate backupcode page
      }
    },
    async getMFADetails ({ commit, dispatch }) {
      const response = await this.$http.get(ApirUrls.GET_MFA)
      commit('setMFAType', response.data.mfa_type.toUpperCase())
      return response
    }
  },
  getters: {
    tb4t: state => {
      return state.tb4t
    },
    isLoggedIn: state => {
      return state.isLoggedIn
    },
    userData: state => {
      return state.userData
    },
    userDataRequested: state => {
      return state.userDataRequested
    },
    mfaEmailHint: state => {
      return state.mfaEmailHint
    },
    billingData: state => {
      return state.billingData
    },
    submitting: state => {
      return state.submitting
    },
    nextRoute: state => {
      return state.toRoute
    },
    modalOpen: state => {
      return state.modalOpen
    },
    captchaToken: state => {
      return state.captchToken
    },
    getMFAType: state => {
      return state.mfaType
    },
    getMFAEmailType: state => {
      return Constants.MFA_EMAIL_TYPE
    },
    getMFATotpType: state => {
      return Constants.MFA_TOTP_TYPE
    },
    isMFATypeEmail: state => {
      return state.mfaType && state.mfaType === Constants.MFA_EMAIL_TYPE
    },
    isMFATypeTotp: state => {
      return state.mfaType && state.mfaType === Constants.MFA_TOTP_TYPE
    },
    isMFAEnabled: state => {
      return state.mfaType
    },
    isMFAShowEnableNotice: state => {
      return state.isMFAShowEnableNotice
    },
    isMFAShowDisableNotice: state => {
      return state.isMFAShowDisableNotice
    },
    isShowBackupCodeAlert: state => {
      return state.isShowBackupCodeAlert
    },
    isMFAAuthTokenExpired: state => {
      return state.isMFAAuthTokenExpired
    },
    isMFALoginTokenExpired: state => {
      return state.isMFALoginTokenExpired
    },
    isTBMcafeeDiscountApplied: state => {
      return state.isMcafeeDiscountApplied
    }
  }
})
