import products from '@/data/product-details.js'
import saleDetails from '@/data/sale-details.js'
import pricingFallback from '@/data/pricing.js'
import axios from 'axios'

class PricingService {
  constructor () {
    this.ENV = process.env.CONFIG
    this.products = products
    this.saleDetails = saleDetails
    this.pricing = pricingFallback
    this.received = false
    this.couponPricing = null
    this.couponRequested = false
  }

  validateCoupon (couponCode) {
    return new Promise((resolve, reject) => {
      axios.get(this.ENV.coreBaseUrl + '/api/getCoupon', {
        'params': { couponCode }
      }).then(response => {
        if (response.data.coupon_valid) {
          resolve()
        } else {
          reject()
        }
      }).catch(() => {
        reject()
      })
    })
  }

  getPricingData () {
    if (this.pricingReceived) return
    axios.get(this.ENV.coreBaseUrl + '/api/getPricingDetails').then((response) => {
      this.pricing = response.data
      this.pricingReceived = true
    })
  }

  getCouponPricing (couponCode) {
    this.validateCoupon(couponCode).then(() => {
      axios.get(this.ENV.paymentBaseUrl + '/v2/pricing', {
        'params': {
          app: 'TUNNELBEAR',
          couponCode
        }
      }).then(response => {
        this.couponPricing = response.data
        this.couponRequested = true
      }).catch(() => {
        this.couponRequested = true
      })
    }).catch(() => {
      this.couponRequested = true
    })
  }

  salePrice (product, productPricing) {
    let salePrice = Number(productPricing.salePrice) || 0
    if (this.saleDetails.isLive &&
        this.saleDetails.salePrices[product]) {
      salePrice = Number(this.saleDetails.salePrices[product])
    }
    return salePrice
  }

  monthlyPrice (product, regular, isTrial, charge) {
    if (isTrial || product === 'MONTHLY_PAYMENT') {
      return regular / this.products[product].cycleMonths
    }
    return charge / this.products[product].cycleMonths
  }

  savings (product, productPricing, full, charge) {
    let plan = Number(productPricing.regularPrice)
    const sale = this.salePrice(product, productPricing)
    if (sale && sale === charge) {
      plan = sale
    }
    const planPriceRatio = plan / full

    const savings = {
      plan: {
        percent: ((1 - planPriceRatio) * 100).toFixed(0),
        value: (full - plan).toFixed(2)
      },
      total: {
        percent: ((1 - planPriceRatio) * 100).toFixed(0),
        value: (full - plan).toFixed(2)
      }
    }

    const additional = full - charge - savings.plan.value
    if (additional > 0.1) {
      savings.additional = {
        percent: (additional / full * 100).toFixed(0),
        value: additional.toFixed(2)
      }
      savings.total = {
        percent: ((full - charge) / full * 100).toFixed(0),
        value: (full - charge).toFixed(2)
      }
    }
    return savings
  }

  getValues (pricingData) {
    const pricing = pricingData || this.pricing
    const pricingValues = {}
    const monthlyFullPrice = Number(pricing.MONTHLY_PAYMENT.regularPrice)
    Object.keys(pricing).forEach((product) => {
      pricingValues[product] = {}
      const productPricing = pricing[product]
      try {
        const full = monthlyFullPrice * this.products[product].cycleMonths
        const regular = Number(productPricing.regularPrice)
        const charge = Number(productPricing.chargePrice)
        const isTrial = product.toLowerCase().indexOf('trial') > -1 || charge < 0.1
        const monthly = this.monthlyPrice(product, regular, isTrial, charge)

        pricingValues[product].fullPrice = full.toFixed(2)
        pricingValues[product].chargePrice = charge.toFixed(2)
        pricingValues[product].monthlyPrice = monthly.toFixed(2)
        pricingValues[product].regularPrice = regular.toFixed(2)
        if (charge !== full && !isTrial) {
          pricingValues[product].savings = this.savings(product, productPricing, full, charge)
        }

        const sale = this.salePrice(product, productPricing)
        if (sale) {
          pricingValues[product].salePrice = sale.toFixed(2)
          pricingValues[product].saleMonthlyPrice = (sale / this.products[product].cycleMonths).toFixed(2)
        }
      } catch (err) {}
    })
    return pricingValues
  }

  getTBMcafeeLandingPrices (pricingData) {
    const pricing = pricingData || this.pricing
    const pricingValues = {}
    const monthlyFullPrice = Number(pricing.TUNNELBEAR_BY_MCAFEE_MONTHLY_739.regularPrice)
    Object.keys(pricing).forEach((product) => {
      pricingValues[product] = {}
      const productPricing = pricing[product]
      try {
        const full = monthlyFullPrice * this.products[product].cycleMonths
        const regular = Number(productPricing.regularPrice)
        const charge = Number(productPricing.chargePrice)
        const isTrial = product.toLowerCase().indexOf('trial') > -1 || charge < 0.1
        const monthly = this.monthlyPrice(product, regular, isTrial, charge)

        pricingValues[product].fullPrice = full.toFixed(2)
        pricingValues[product].chargePrice = charge.toFixed(2)
        pricingValues[product].monthlyPrice = this.getFlooredFixed(monthly, 2)
        pricingValues[product].regularPrice = regular.toFixed(2)
        if (charge !== full && !isTrial) {
          pricingValues[product].savings = this.savings(product, productPricing, full, charge)
        }
      } catch (err) {}
    })
    return pricingValues
  }

  // to get Mcafee price as 3.99 instead of 4.0
  getFlooredFixed (price, digit) {
    return (Math.floor(price * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(digit)
  }

  getSale () {
    // check for backend sale
    Object.keys(this.pricing).forEach((product) => {
      if (
        this.pricing[product].salePrice &&
        this.pricing[product].salePrice === this.pricing[product].chargePrice
      ) {
        this.saleDetails.salePrices[product] = Number(this.pricing[product].salePrice).toFixed(2)
      }
    })
    return this.saleDetails
  }

  get values () {
    return this.getValues()
  }

  get couponValues () {
    return this.getValues(this.couponPricing)
  }

  get sale () {
    return this.getSale()
  }

  get tbMcafeePricingValues () {
    return this.getTBMcafeeLandingPrices()
  }

  get tbMcafeeCouponValues () {
    return this.getTBMcafeeLandingPrices(this.couponPricing)
  }
}

export { PricingService }
