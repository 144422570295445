export default {
  'GIFT_OF_BEAR': {
    'chargePrice': '59.88',
    'regularPrice': '59.88'
  },
  'YEARLY_PAYMENT_RECURRING_TRIAL': {
    'nextBillDate': '',
    'chargePrice': '0.00',
    'regularPrice': '59.88'
  },
  'TB4T_YEARLY_PAYMENT': {
    'chargePrice': '69.00',
    'regularPrice': '69.00'
  },
  'TB4T_YEARLY_PAYMENT_TRIAL': {
    'nextBillDate': '',
    'chargePrice': '0.00',
    'regularPrice': '69.00'
  },
  'MONTHLY_PAYMENT': {
    'chargePrice': '9.99',
    'regularPrice': '9.99'
  },
  'TWO_YEAR_PAYMENT': {
    'chargePrice': '99.99',
    'regularPrice': '99.99'
  },
  'THREE_YEAR_PAYMENT': {
    'chargePrice': '120.00',
    'regularPrice': '120.00'
  },
  'TUNNELBEAR_REMEMBEAR_THREE_YEAR_BUNDLE': {
    'chargePrice': '120.00',
    'regularPrice': '120.00'
  },
  'YEARLY_PAYMENT_RECURRING': {
    'chargePrice': '59.88',
    'regularPrice': '59.88'
  },
  'TUNNELBEAR_BY_MCAFEE_MONTHLY_739': {
    'chargePrice': '7.99',
    'regularPrice': '7.99'
  },
  'TUNNELBEAR_BY_MCAFEE_MONTHLY_1641': {
    'chargePrice': '7.99',
    'regularPrice': '7.99'
  },
  'TUNNELBEAR_BY_MCAFEE_YEARLY_739': {
    'chargePrice': '47.99',
    'regularPrice': '47.99'
  },
  'TUNNELBEAR_BY_MCAFEE_YEARLY_1641': {
    'chargePrice': '47.99',
    'regularPrice': '47.99'
  }
}
